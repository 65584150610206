// Common variables
$primary-color: #0066cc;
$text-dark: #1a1a1a;
$text-muted: #666;
$border-color: #e9ecef;
$background-light: #f8f9fa;
$white: #fff;
$shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
$success-color: #00b377;

// Mixins
@mixin card-base {
	background: $white;
	border-radius: 8px;
	box-shadow: $shadow;
}

@mixin flex-center {
	display: flex;
	align-items: center;
}

@mixin text-base {
	font-size: 14px;
	color: $text-muted;
}

// Base layout
.page-container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	width: 100%;
	background-color: $background-light;
}

.page-header {
	background: $white;
	border-bottom: 1px solid $border-color;
	width: 100%;
	padding: 0;

	&__title h1 {
		font-size: 24px;
		font-weight: 600;
		color: $text-dark;
		margin: 0;
	}

	&__content {
		display: flex;
		width: 100%;
		.topnav .left .title h3 {
			text-transform: none;
		}
	}

	&__notification {
		@include flex-center;
		justify-content: center;
		cursor: pointer;

		svg {
			width: 20px;
			height: 20px;
			color: $text-dark;
		}
	}
}

.page-content {
	flex: 1;
	padding: 24px 32px;
	width: 100%;
	overflow-y: auto;
	margin-top: 1rem;

	@media (max-width: 768px) {
		padding: 16px;
	}
}

// Ecommerce report styles
.ecommerce-report {
	&__card {
		@include card-base;
		transition: transform 0.2s ease;
		margin-bottom: 24px;
		width: 100%;
		position: relative;

		.button-container {
			margin: 2% 5%;
		}

		&__blur {
			position: absolute;
			top: 24px;
			left: 24px;
			right: 24px;
			bottom: 24px;
			width: calc(100% - 48px);
			height: calc(100% - 48px);
			-webkit-backdrop-filter: blur(4px);
			backdrop-filter: blur(4px);
			display: flex;
			align-items: flex-start;
			justify-content: center;
			z-index: 10;
			border-radius: 8px;

			&__content {
				display: flex;
				align-content: flex-start;
				flex-direction: column;
				align-items: center;
				text-align: center;
				padding: 2rem;
				background: white;
				border-radius: 8px;
				box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
				max-width: 90%;
				margin: 0 auto;
				.button-container {
					width: 300px;
					margin: 5%;
				}
			}
		}
	}

	&__card-content {
		@include flex-center;
		padding: 24px;
		gap: 24px;
		width: 100%;

		@media (max-width: 768px) {
			flex-direction: column;
			align-items: flex-start;
			padding: 16px;
		}
	}

	&__card-icon {
		min-width: 48px;
		height: 48px;
		@include flex-center;
		justify-content: center;
		border-radius: 8px;

		svg {
			width: 24px;
			height: 24px;
			color: $success-color;
		}
	}

	&__card-text {
		flex: 1;
		min-width: 0;
	}

	&__card-heading {
		font-size: 18px;
		font-weight: 500;
		color: $text-dark;
		margin-bottom: 8px;
	}

	&__card-description {
		@include text-base;
		margin: 0;
	}

	&__card-button {
		@media (max-width: 768px) {
			width: 100%;
			text-align: center;
		}
	}
}

// Ecommerce report view styles
.ecommerce-report-view {
	&__breadcrumb {
		@include flex-center;
		gap: 8px;
		margin-bottom: 16px;
		@include text-base;

		a {
			color: $primary-color;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		span {
			display: inline-flex;
			align-items: center;
		}
	}

	&__header {
		margin-bottom: 24px;

		h1 {
			font-size: 24px;
			font-weight: 600;
			margin-bottom: 8px;
		}

		p {
			@include text-base;
		}
	}

	&__controls {
		@include flex-center;
		justify-content: space-between;
		margin-bottom: 24px;
	}

	&__timeframe {
		label {
			display: block;
			margin-bottom: 8px;
			@include text-base;
		}

		select {
			padding: 8px 16px;
			border: 1px solid $border-color;
			border-radius: 4px;
			min-width: 200px;
		}
	}

	&__period h2 {
		font-size: 16px;
		font-weight: 500;
		color: $text-dark;
	}

	&__settings {
		@include flex-center;
		gap: 8px;
		padding: 8px 16px;
		border: 1px solid $border-color;
		border-radius: 4px;
		background: $white;
		cursor: pointer;

		svg {
			width: 16px;
			height: 16px;
		}
	}

	&__month-selector {
		background: none;
		border: none;
		font-size: 16px;
		font-weight: 500;
		color: $text-dark;
		cursor: pointer;
		padding-right: 20px;

		&:focus {
			outline: none;
		}
	}

	&__table {
		@include card-base;
		margin-top: 24px;

		&-header {
			background: $background-light;
			border-bottom: 1px solid $border-color;
			font-weight: 500;
		}

		&-row {
			display: grid;
			grid-template-columns: repeat(9, 1fr);
			align-items: center;

			&:hover {
				background-color: $background-light;
			}
		}

		&-cell {
			padding: 16px;
			text-align: left;
			font-size: 14px;

			.net-results & {
				font-weight: 500;
				border-top: 1px solid $border-color;
			}
		}
	}
}
