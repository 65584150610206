.modal {
	&__wrapper {
		position: fixed;
		z-index: 11100; //above all
		left: 0;
		top: 0;
		width: 100%; /* Full width */
		height: 100%; /* Full height */
		overflow: auto; /* Enable scroll if needed */
		background-color: $modal-opacity; /* Black w/ opacity */
	}
	&__container {
		&.no-overflow {
			overflow: hidden;
		}
		.icon-transparent {
			background-color: $dark-blue !important;
		}
		border-radius: $small-border;
		@include box-shadow-default();
		background-color: $white;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-height: calc(100vh - 8rem);
		max-width: calc(100vw - 16rem);
		padding: 1.5rem 3rem;
		min-width: $min-width-modal;
		width: $min-width-modal;
		overflow: auto;
	}
	&__header {
		display: flex;
		justify-content: flex-end;
		h2 {
			font-size: $font-size-header;
		}
		.modal__close-button-container {
			margin: 0;
			margin-right: -1rem;
			.button-icon-with-text .button-text.button-icon .icon.icon-transparent {
				color: $icon-gray;
			}
		}
	}

	&__footer {
		margin-top: 1rem;
	}

	&__content {
		max-height: calc(100vh - 22rem);
	}
	&::content {
		animation: {
			name: animate-top;
			duration: 0.4s;
		}
	}
}

.show {
	display: block;
}

.hide {
	display: none;
}

.align-center {
}
.align-left {
	left: 21%;
}
.align-right {
	left: 79%;
}
.align-top {
	top: 23%;
}
.align-bottom {
	top: 74%;
}

/* Add Animation */
@keyframes animate-top {
	from {
		top: -100%;
		opacity: 0;
	}
	to {
		top: 0;
		opacity: 1;
	}
}

.no-token-modal {
	min-width: $min-width-modal;
	.button-container.ok-button {
		width: 100%;
		button {
			width: unset;
		}
	}
}

.tutorial-modal {
	overflow: unset;
	min-height: 45%;
	display: flex;
	flex-direction: column;
}

.confirmation-modal {
	p {
		text-align: center;
	}
}

.modal__container.dashboard-page__suggestion-detail-modal {
	width: $min-width-modal * 1.5;
}

.modal__container.setting-page__modal.align-center {
	// height: 25rem;
	// overflow: hidden;
	.modal__content {
		max-height: 17rem;
	}
}

.modal__container.setting-page__modal.project-deletion.align-center {
	height: 25rem;
	overflow: hidden;
	.modal__content {
		max-height: 17rem;
	}
}
