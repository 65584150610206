.report-page-template {
	height: 100%;
	width: 100%;
	overflow-y: clip;

	.topnav .right {
		&__inner-container {
			.button-container {
				margin: 0.5rem 1%;
				.no-background-color-button {
					background-color: $white;
					border: 1.5px solid $dark-blue;
					.button-text {
						color: $dark-blue;
					}
				}
			}
		}
	}

	&__breadcrumbs-container {
		height: 48px; // Fixed height for the breadcrumbs area
		padding: 0 24px;
	}

	&__content {
		max-height: 90vh;
		min-height: 90vh;
		background-color: $background-darker-gray;
		padding-top: 1rem;
		overflow-y: scroll;
		&__container {
			@include container-default(
				$align-items: flex-start,
				$flex-direction: column,
				$justify-content: space-around
			);
			&__delete-modal {
				h5 {
					margin-bottom: 2rem;
				}
				&__buttons {
					@include container-default(
						$flex-direction: row,
						$justify-content: space-between
					);
				}
				overflow: unset;
			}
			&--template-inputs,
			&--template-items {
				@include container-default(
					$align-items: flex-start,
					$flex-direction: row,
					$justify-content: space-around
				);
				width: 100%;
				.MuiIconButton-root {
					padding: 6px;
				}
			}
			.button-container {
				margin: 1.5rem;
				.button-text {
					font-size: 1.8rem;
				}
			}
			&--item {
				@include droppable-item-container();
				&--drag {
					background-color: $turqoise;
				}

				&.pointer {
					@include container-default(
						$align-items: center,
						$justify-content: space-around,
						$flex-direction: row
					);
					p {
						color: $dark-blue;
						margin-bottom: 0;
						margin-right: auto;
						width: 100%;
					}
					cursor: pointer;
					&.highlight {
						border: 0.2rem dotted $dark-blue;
					}
				}
			}

			&--selected,
			&--selection {
				@include box-shadow-light();
				background-color: $light-gray;
				flex: 1;
				min-height: 30rem;
				border-radius: $medium-border;
				flex: 1;
				padding: 2rem;
				margin: 1.5rem;
				margin-top: 0;
				&__header {
					text-align: start;
					margin-bottom: 2rem;
					color: $dark-blue;
					font-size: $font-size-large;
					&.description {
						font-size: $font-size;
						margin-bottom: 4rem;
					}
					&.archieve {
						@include container-default(
							$flex-direction: row,
							$align-items: center,
							$justify-content: space-between
						);
					}
				}
				&__button {
					// max-width: 25rem;
					display: flex;
					width: auto;
					margin-top: 3rem;
					.button-container {
						min-width: 25rem;
					}
				}
			}
			&__customization {
				width: 100%;
				&__container {
					@include container-default(
						$align-items: flex-start,
						$flex-direction: column,
						$justify-content: space-around
					);
					margin: 1.5rem;
					margin-top: 0;
					padding: 2rem 3rem;
					background-color: $white;
					border-radius: $medium-border;
					.input__container {
						margin: 0rem;
						p {
							font-size: $font-size-base;
						}
					}
					h6 {
						margin-bottom: 2rem;
						font-size: 1.8rem;
					}
					&__template-name {
						@include container-default(
							$align-items: flex-start,
							$flex-direction: column,
							$justify-content: space-around
						);
						width: 50%;
						h6 {
							margin-bottom: 1rem;
						}
						&__description {
							margin-bottom: 1.5rem;
						}
						&__input {
							min-width: 100%;
						}
					}
					&__color-picker {
						width: 50%;
						h6 {
							margin-bottom: 3rem;
						}
						&__container {
							@include container-default(
								$align-items: flex-start,
								$flex-direction: row,
								$justify-content: space-between
							);
							min-width: 100%;
							&__item {
								flex-basis: 48%;
								&__picker-container {
									border: $tiniest-border solid $gray-border;
									border-radius: $small-border;
								}
								.MuiFormControl-root {
									padding: 0.3rem;
									margin-left: 0.5rem;
								}
								.ColorPicker-MuiButton-root {
									height: 37px;
									width: 37px;
									margin: 0;
									box-shadow: none;
									border: 1px dotted $dark-yellow;
								}
								.MuiInput-underline:before {
									border-bottom: none;
								}
								.MuiInput-underline:after {
									border-bottom: none;
								}
								.MuiInputBase-input {
									font-family: $lato;
									font-weight: $font-bold;
								}
								p {
									font-size: $font-size-base;
								}
							}
						}
					}
					&__logo {
						@include container-default(
							$align-items: flex-start,
							$flex-direction: row
						);
						h6 {
							margin-bottom: 3rem;
						}
						width: 100%;
						&__link {
							flex-basis: 50%;
						}
						&__preview {
							flex-basis: 50%;
							&__image {
								margin-left: 3rem;
								height: 100%;
								max-height: 120px;
							}
						}
					}
				}
			}
		}
		&__save-automated-button {
			margin-left: auto;
			margin-top: -1rem;
			max-width: 60%;
			margin-bottom: 1rem;
			&--button-text {
				font-size: 1.4rem !important;
				font-weight: 400;
			}
		}
		&__save-button {
			margin-left: 2rem;
		}
		&__star-icon {
			color: $light-orange;
		}
	}
	&__selection {
		min-width: 25rem;
	}

	&__breadcrumbs {
		min-height: 24px;
		padding: 0 0 10px 3rem;
	}

	&__modal {
		&-content {
			padding: 0 24px;
			min-width: 400px;
		}

		&-title {
			font-size: 24px;
			font-weight: 600;
			margin-bottom: 24px;
			color: $dark-blue;
			text-align: center;
		}

		&-section {
			margin-bottom: 24px;

			&:last-of-type {
				margin-bottom: 32px;
			}
		}

		&-section-title {
			font-size: 16px;
			font-weight: 500;
			margin-bottom: 12px;
			color: $dark-blue;
		}

		&-actions {
			display: flex;
			justify-content: center;

			.blue-button {
				min-width: 120px;
			}
		}
	}
}

.report-list {
	width: 100%;
	padding: 1rem 2rem 2rem 2rem;
	.report-overview {
		width: 100%;
		padding: 24px;
		background-color: $white;
		border-radius: $medium-border;
		@include box-shadow-light();

		&__title {
			font-size: 2rem;
			font-weight: 600;
			color: $dark-blue;
			margin-bottom: 24px;
		}

		&__table {
			width: 100%;
			background: $white;
			border-radius: $medium-border;
			border: 1px solid $light-gray-border;
		}

		&__header {
			display: grid;
			grid-template-columns: 1fr 1fr 2fr;
			padding: 16px;
			border-bottom: 1px solid $light-gray-border;
			background-color: $light-gray;

			&-cell {
				font-weight: 500;
				color: $dark-blue;
				display: flex;
				align-items: center;

				&:last-child {
					padding-left: 16px;
				}
			}
		}

		&__row {
			display: grid;
			grid-template-columns: 1fr 1fr 2fr;
			padding: 16px;
			border-bottom: 1px solid $light-gray-border;

			&:last-child {
				border-bottom: none;
			}
		}

		&__cell {
			display: flex;
			align-items: center;

			// &:last-child {
			// 	padding-left: 16px;
			// }
		}

		.status-badge {
			padding: 4px 12px;
			border-radius: 12px;
			font-size: 14px;
			font-weight: 500;

			&--live {
				background-color: rgba(25, 135, 84, 0.1);
				color: #198754;
			}

			&--unsubscribed {
				background-color: rgba(220, 53, 69, 0.1);
				color: #dc3545;
			}
		}

		.action-buttons {
			display: flex;
			gap: 8px;
		}

		.action-button {
			padding: 8px 16px;
			border-radius: 4px;
			border: none;
			background: none;
			color: $blue;
			cursor: pointer;
			font-size: 14px;

			&:hover:not(:disabled) {
				background-color: $light-gray;
			}

			&:disabled {
				color: $light-gray-border;
				cursor: not-allowed;
				opacity: 0.7;
			}
		}
		&__create-button {
			margin-top: 24px;
			color: $blue;
			background: none;
			border: none;
			padding: 8px 0;
			cursor: pointer;
			font-size: 14px;

			&:hover {
				text-decoration: underline;
			}
		}

		&__email-modal {
			max-width: 500px;
			width: 100%;

			&-content {
				padding: 24px;

				h3 {
					font-size: 24px;
					font-weight: 600;
					margin-bottom: 24px;
					color: $dark-blue;
				}

				.email-input-section {
					display: flex;
					gap: 16px;
					margin: 24px 0;

					.input__container {
						flex: 1;
						margin: 0;
					}

					.button-container {
						margin-top: 0;
						padding-bottom: 1.5rem;
						min-width: 80px;
					}
				}

				.email-list-section {
					margin: 24px 0;
					max-height: 200px;
					overflow-y: auto;
					border: 1px solid $light-gray-border;
					border-radius: $medium-border;

					.email-item {
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 12px 16px;
						border-bottom: 1px solid $light-gray-border;

						&:last-child {
							border-bottom: none;
						}

						span {
							color: $dark-blue;
							font-size: 14px;
						}

						.MuiIconButton-root {
							padding: 8px;
							color: $dark-blue;

							&:hover {
								color: $red;
								background-color: rgba($red, 0.1);
							}
						}
					}
				}

				.modal-actions {
					display: flex;
					justify-content: flex-end;
					gap: 16px;
					margin-top: 32px;

					.button-container {
						min-width: 100px;
					}
				}
			}
		}
	}
}

.breadcrumbs {
	display: flex;
	align-items: center;
	font-size: 14px;

	&__link {
		color: #0066cc;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	&__current {
		color: #666;
	}

	&__separator {
		margin: 0 8px;
		color: #666;
	}
}
